<template>
<div>
  <div class="container-fluid firstStepIronPalace">
      <div class="first-step-content">
        <div class="row">
          <div class="col-12 ">
            <h1 class="text-center">¡BIENVENIDO!</h1>
            <h2 class="text-center">MIS DISPOSITIVOS INSURAMA Y GNP SEGUROS</h2>
          </div>
        </div>
        <div class="row my-4">
            <div class="col-12">
              <div class="text-center">
                <span >Busca tu tienda</span><br />
              </div>
              <div class="text-center">
                <select class="col-9 col-sm-4" v-model="storeSelected" required :class="setFieldClass(isValidTextClass(storeSelected, 0, continueClicked))">
                  <option v-for="(store, index) in stores" :key="index" :value="store.num_ticket">{{store.name}}</option>
                </select>
              </div>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ScanCode :reset="reset" :validationCode="validationCodeAfterClicked" @codeValidated="codeValidated"></ScanCode>
          </div>
        </div>
        <div class="row my-4 py-2 d-flex justify-content-center">
          <div class="col-sm-6">
          <p class="text-center">Selecciona el tipo de dispositivo que quiere asegurar
            tu cliente con Mis Dispositivos Insurama y GNP Seguros.</p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-12 col-lg-4 col-xl-3 mb-3">
              <DeviceOption
                firstDevice="SMARTPHONE"
                firstImg="smartphone"
                secondDevice="SMARTWATCH"
                secondImg="smartwatch"
                thirdDevice="TABLETS"
                thirdImg="tablet"
                :value="smartphoneVoltageProductTypeId"
                :selected="productSelected"
                @selected="showRangeTable"
              ></DeviceOption>
          </div>
          <div class="col-md-12 col-lg-4 col-xl-3 mb-3">
              <DeviceOption
                firstDevice="LAPTOP"
                firstImg="laptop"
                secondDevice="SMART TV"
                secondImg="smartTV"
                thirdDevice="CONSOLAS"
                thirdImg="consolas"
                :value="laptopVoltageProductTypeId"
                :selected="productSelected"
                @selected="showRangeTable"
              ></DeviceOption>
          </div>
          <div class="col-md-12 col-lg-4 col-xl-3 mb-3">
              <DeviceOption
                firstDevice="2 SMARTPHONES"
                firstImg="2smartphones"
                secondDevice="MULTIMEDIA"
                secondImg="multimedia"
                thirdDevice="ELECTRODOMÉSTICOS"
                thirdImg="electrodomesticos"
                :value="multideviceVoltageProductTypeId"
                :selected="productSelected"
                @selected="showRangeTable"
              ></DeviceOption>
          </div>
        </div>
        <div v-if="deviceSelected" class="row justify-content-center mt-3">
          <div class="col-5">
              <RangeTable :rates="rates" @rangeSelected="onRangeSelected($event)"></RangeTable>
              <div class="buttons">
                <button @click="validateFirstStep()" class="continue">CONTINUAR</button>
                <!-- <button class="cancel">CANCELAR</button> -->
              </div>
          </div>
        </div>
      </div>
</div>
  <footer>
    <div class="col-12 p-0">
      <b-button v-show="deviceSelected" class="w-100 m-0 p-0" style="background-color: transparent" v-b-modal.modal-7><div class="help p-3">AYUDA</div></b-button>
      <b-button v-show="!deviceSelected" class="w-100 m-0 p-0" style="background-color: transparent" v-b-modal.modal-8><div class="help p-3">AYUDA</div></b-button>
    </div>
  </footer>
</div>
</template>
<script>
import ScanCode from "@/components/ironPalace/ScanCode";
import DeviceOption from "@/components/ironPalace/DeviceOption";
import RangeTable from "@/components/ironPalace/RangeTable.vue";
import '@trevoreyre/autocomplete-vue/dist/style.css'
import { getStoresPH } from '@/api/ApiClient.js'
import { getters } from '@/state/store';

export default {
  name: "FirstStepIronPalace",
  components: {
    ScanCode,
    DeviceOption,
    RangeTable
  },
  props: {
    title: String,
    reset: Boolean
  },
  data() {
    return {
      // Products type ids
      multideviceVoltageProductTypeId: 8,
      smartphoneVoltageProductTypeId: 7,
      laptopVoltageProductTypeId: 12,

      specialPricePH: 12,

      products: [],
      rates: [],
      codeAccepted: false,
      deviceSelected: false,
      productSelected: null, // product_type_id
      rangeSelected: null, // product_category_id
      showHelp: [],
      continueClicked: false,

      stores: [],
      storeSelected: '',
      validationCodeAfterClicked: true,

      helpArray: []
    };
  },
  created() {
    this.getProductPrices();
    this.getStores();

    this.helpArray = [
        [
          {
            info: "DAÑOS, CAÍDAS, ETC.",
            img: require("../../assets/palacio-hierro/rotura.svg")
          },
          {
            info: "DAÑOS POR LÍQUIDOS",
            img: require("../../assets/palacio-hierro/daño.svg")
          },
          {
            info: "ROBO CON O SIN VIOLENCIA",
            img: require("../../assets/palacio-hierro/robo.svg")
          },
          {
            info: "VARIACIÓN DE VOLTAJE",
            img: require("../../assets/palacio-hierro/voltage.svg")
          },
        ],
        [
          {
            info: "DAÑOS, CAÍDAS, ETC.",
            img: require("../../assets/palacio-hierro/laptop_roturas.svg")
          },
          {
            info: "DAÑOS POR LÍQUIDOS",
            img: require("../../assets/palacio-hierro/laptop_daños.svg")
          },
          {
            info: "ROBO CON O SIN VIOLENCIA",
            img: require("../../assets/palacio-hierro/laptop_robo.svg")
          },
          {
            info: "VARIACIÓN DE VOLTAJE",
            img: require("../../assets/palacio-hierro/voltage.svg")
          },
        ],
        [
          {
            info: "DAÑOS, CAÍDAS, ETC.",
            img: require("../../assets/palacio-hierro/multi_roturas.svg")
          },
          {
            info: "DAÑOS POR LÍQUIDOS",
            img: require("../../assets/palacio-hierro/multi_daños.svg")
          },
          {
            info: "ROBO CON O SIN VIOLENCIA",
            img: require("../../assets/palacio-hierro/multi_robo.svg")
          },
          {
            info: "VARIACIÓN DE VOLTAJE",
            img: require("../../assets/palacio-hierro/voltage.svg")
          },
           {
            info: "3 AÑOS DE GARANTÍA EXTENDIDA",
            img: require("../../assets/palacio-hierro/calendario.svg"),
            class: "last"

          },

        ]
      ]
  },
  watch: {
    reset: {
      handler(newVal){
        if(newVal){
          this.rates = []
          this.deviceSelected = false;
          this.rangeSelected = null;
          this.codeAccepted = false;
          this.productSelected = null;
          this.storeSelected = '';
          this.continueClicked = false;
          this.onRangeSelected();
        }
      },
      deep: true
    },
  },
  methods: {
    showRangeTable(product) {
      this.deviceSelected = true
      this.rangeSelected = null
      this.productSelected = product;
      const productRates = this.products.find(p => p.product_type_id === product).prices.sort((p1, p2) => {
          return p1.min_value - p2.min_value;
      });

      this.rates = productRates.filter(q => !(q.min_value === 15001 && q.max_value === 25000) && !(q.min_value === 25001 && q.max_value === 50000) && (q.valid_until === null || (q.min_value === 15001 && q.max_value === 125000) || new Date(q.valid_until) > new Date()));
      this.$emit('showHelp', this.helpArray[0], this.rates);

      if (this.productSelected == this.smartphoneVoltageProductTypeId) {
          this.rates = productRates.filter(q => q.special_prices === true && q.special_price_version === this.specialPricePH);
          this.$emit('showHelp', this.helpArray[0], this.rates);
      }

      else if(this.productSelected  == this.laptopVoltageProductTypeId) this.$emit('showHelp', this.helpArray[1], this.rates)
      else if(this.productSelected  == this.multideviceVoltageProductTypeId) this.$emit('showHelp', this.helpArray[2], this.rates)

      this.$nextTick(() => {
       const el = document.getElementsByClassName('range-table')[0]
       if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el.scrollIntoView({behavior: 'smooth'});
        }
      })

    },

    getImgUrl(img){
      return require (`../../assets/palacio-hierro/${img}.svg`)
    },
    getProductPrices() {
      this.products = getters.getProducts().filter(p => p.product_type_id === this.multideviceVoltageProductTypeId
      || p.product_type_id === this.smartphoneVoltageProductTypeId
      || p.product_type_id === this.laptopVoltageProductTypeId);
    },
    validateFirstStep() {
      this.continueClicked = true;
      if(!this.codeAccepted){
        this.validationCodeAfterClicked = false;
      }
      if(this.rangeSelected !== null && this.codeAccepted && this.storeSelected !== ''){
        this.$emit('storeSelected', this.storeSelected);
        this.reset = false
        this.$emit('nextStep')
      }

    },
    async getStores(){
      try{
          const storesPh = await getStoresPH();
          Object.keys(storesPh).forEach((store) => this.stores.push({
            num_ticket: store,
            name: storesPh[store]
          }))
      }catch{
        this.$emit('error')
      }
    },
    onRangeSelected($event) {
      if ($event) {
        const searchRange = this.rates.find(r => r.id == $event)

        if (searchRange) {
          this.rangeSelected=$event

          const productId = this.products.find((p)=> p.product_type_id === this.productSelected).id;
          this.$emit('onSelectedProductAndRange', { product: productId, product_type_id: this.productSelected, range_id: $event, max_value: searchRange.max_value, min_value: searchRange.min_value  })
        }
      } else {
        this.rangeSelected = null;
        this.$emit('onSelectedProductAndRange', {})
      }
    },

    codeValidated(code){
      if(code) {
        this.codeAccepted = true
        this.$emit('codeValidated', code)
      }
      else {
        this.codeAccepted = false
        this.$emit('codeValidated', code)
      }

    },

    setFieldClass(value){
      if(value === 'valid') return 'val'
      else if(value === 'notValid') return 'notVal'
      else return '';
    },
  }
};
</script>

<style lang="scss">
*,
*:after,
*:before {
  box-sizing: border-box;
}

.firstStepIronPalace{
  display: flex;
  min-height: calc(100vh - 130px);
  flex-direction: column;
  justify-content: space-between;
  .autocomplete {
      position: relative;
      border: 1px solid #868c90;
      border-radius: 6px;
      padding: 7px;
    }

  .val .autocomplete,  select.val{
    border-color: green;
    border-width: 2px 2px 2px;
  }

  .notVal .autocomplete, select.notVal {
    border-color: red;
    border-width: 2px 2px 2px;
  }
  .ss-input {
    margin-top: 4%;
    .autocomplete-input {
      border: none;
      box-shadow: none;
      background-color: transparent;
      outline: none;
      color: #495057;
      width: 100%;
      padding: 0 0 0 40px;
      height: auto;
    }
  }

  select{
    height: 35px;
    border-radius: 7px;
    color: gray;
    font-size: 16px;
    padding: 4px 10px;
    text-align: left;
  }
}
</style>

<style scoped>
h2 {
  font-size: 1.6rem;
}

.input-wraper {
  text-align: left;
  font-weight: bold;
}

.buttons {
  display: flex; /* establish flex container */
  flex-direction: column; /* stack flex items vertically */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
}
.help-section {
  display: flex; /* establish flex container */
  flex-direction: column; /* stack flex items vertically */
  justify-content: space-around;
  align-items: center; /* center items horizontally, in this case */
  width: 25%;
  margin-right: 1rem;
  height: 12rem
}
.help-p {
  width: 8rem;
}
.last {
  width: 12rem;
}
button {
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  min-width: 180px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 30px;
}
.continue {
  background-color: #EBB248;
  color: black;
  border: 0px;
  font-weight: bold;
  margin-top: 10px;
  font-family: Gotham-Bold;
}
.information-title {
  font-weight: bold;
  font-size: 1.5em;
}

.icon-wrapper{
    height: 120px;
    display: flex; /* establish flex container */
    flex-direction: row; /* stack flex items vertically */
    justify-content: center; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */
    margin-top: 30px;
  }
  img {
    max-height: 100px;
  }

  .help {
    background-color: #f2f2f2;
    color: #565656;
    cursor: pointer;
    text-align: center;
    font-family: Gotham-Bold;
    border-top: 1px solid #e1e2e3;
  }

  .dropdown-question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .dropdown-question select {
    width: 85%;
  }

</style>